import React from 'react'
import { Link } from 'react-router-dom'
import LazyLoad from 'react-lazyload';
const HomeSlider = () => {
  return (
    <>
      <div className="slider-sec">
        <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-inner"> 
            <div className="carousel-item active" data-bs-interval="3000"> <img src="https://webapi.entab.info/api/image/SJHSMD/public/Images/sld-1.jpg" className="d-block w-100" alt=" St. Joseph High School" /> </div>
            <div className="carousel-item" data-bs-interval="3000"> <img src="https://webapi.entab.info/api/image/SJHSMD/public/Images/sld-2.jpg" className="d-block w-100" alt=" St. Joseph High School" /> </div>
            <div className="carousel-item" data-bs-interval="3000"> <img src="https://webapi.entab.info/api/image/SJHSMD/public/Images/sld-3.jpg" className="d-block w-100" alt=" St. Joseph High School" /></div>
          </div> 
          <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev"> <span className="carousel-control-prev-icon" aria-hidden="true"></span> <span className="visually-hidden">Previous</span> </button> <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next"> <span className="carousel-control-next-icon" aria-hidden="true"></span> <span className="visually-hidden">Next</span> </button>
        </div>  
        <div className="kidslogo">
            <Link to="/KidsCorner"><LazyLoad><img src="https://webapi.entab.info/api/image/SJHSMD/public/Images/kids.png" className="img-fluid" alt=" St. Joseph High School" /></LazyLoad></Link>
        </div>
      </div>
    </>
  )
}

export default HomeSlider
