 
import React, { useEffect, useState } from 'react'; 
import { getBirthdays } from '../Service/Api';
import { Carousel } from 'react-bootstrap';
import LazyLoad from 'react-lazyload';
const Birthday = () => {


    const [data, setData] = useState([]); // State for gallery data
    const [modifiedData, setModifiedData] = useState([]); // State for gallery data
    const [loading, setLoading] = useState(true); // State for loading indicator


    useEffect(() => {
        const fetchData = async () => {
            try {
                const birthdayData = await getBirthdays();
                const filterbirthday = birthdayData.filter((item) => item.category === "Student");
                setData(filterbirthday);
                console.log("Data received.", filterbirthday)
            } catch (error) {
                console.error("Error fetching birthday data:", error);
                // Handle error (e.g., show error message)
            } finally {
                setLoading(false); // Set loading to false after data fetching is complete
            }
        };
        fetchData();
    }, []);

   


    useEffect(() => {
        if (data.length > 0) {
            let newData = [];
            for (let i = 0; i < data.length; i += 2) {
                newData.push(data.slice(i, i + 2)); // Push each set of four objects into newData
            }

            setModifiedData(newData); // Update the state with the modified array
            
        } 
        
    }, [data]); // Add data as a dependency so that this effect runs whenever data changes





    return (
        <> {loading ? (
            <div>Loading...</div>
        ) : (
            <Carousel>
                {modifiedData.length > 0 ? modifiedData.map((item, index) => (
                    <Carousel.Item key={index}>
                        <div className="birthday-block">
                            {item.map((data, index) => (
                                <div className="d-flex" key={index}>
                                    <div className="birthdayImg">
                                   {data.attachments.length>0?<img src={`https://webapi.entab.info/api/image?url=${data.attachments}`} alt="Alternate Text" />:<img src="https://webapi.entab.info/api/image/SJHSMD/public/Images/dummy.jpg" alt="Alternate Text" />}
                                    </div>
                                    <div className="birthdayContent">
                                        <h3>{data.name}</h3>
                                        <p>{data.class}</p>
                                    </div>
                                </div>
                            ))}

                        </div>
                    </Carousel.Item>
                )):(
                    
                    <Carousel.Item>
                    <div className="birthday-block">
                        <div className="d-flex">
                            <div className="birthdayImg">
                            <LazyLoad><img src="https://webapi.entab.info/api/image/SJHSMD/public/Images/dummy.jpg" alt="Alternate Text" /></LazyLoad>
                            </div>
                            <div className="birthdayContent">
                                <h3>Student Name</h3>
                                <p>Class</p>
                            </div>
                        </div>
                        </div>
                        </Carousel.Item>
                )}
            </Carousel>
            )}

        </>
    )
}
export default Birthday;