import React, { useState, useEffect } from 'react'; 
import Header from'../Component/Header'
import Footer from'../Component/Footer'
import { Link } from 'react-router-dom' 
import { getCircular } from "../Service/Api";
import LazyLoad from 'react-lazyload';
const Circular = () => {
    useEffect(() => {
        document.title = 'Circular - St. Joseph’s High School, Dabwali ';  
    }, []); 
    
    const [data, setData] = useState([]);
    const [filterData, setFilteredData] = useState([]);
    const [selectedYear, setSelectedYear] = useState("All");
    const [selectedMonth, setSelectedMonth] = useState("All");
    const [yearRanges, setYearRanges] = useState([]);
    const [availableMonths, setAvailableMonths] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const monthsOfYear = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const handleMonth = (month) => {
        return monthsOfYear[month - 1];
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const galleryData = await getCircular();
                console.log("Fetched data:", galleryData); // Debugging log
                setData(galleryData);

                const uniqueYears = Array.from(
                    new Set(galleryData.map(item => item.fromDate ? new Date(item.fromDate).getFullYear() : null))
                ).filter(year => year != null).sort((a, b) => b - a);

                setYearRanges(uniqueYears);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        console.log("Current data state:", data); // Debugging log
        let filteredData = data;
        let monthsSet = new Set();

        if (selectedYear !== "All") {
            filteredData = data.filter(item => {
                if (item.fromDate) {
                    const year = new Date(item.fromDate).getFullYear();
                    if (parseInt(selectedYear) === year) {
                        const month = new Date(item.fromDate).getMonth() + 1;
                        monthsSet.add(month);
                        return true;
                    }
                }
                return false;
            });
        }

        if (selectedMonth !== "All") {
            filteredData = filteredData.filter(item => {
                const month = item.fromDate ? new Date(item.fromDate).getMonth() + 1 : null;
                return month === parseInt(selectedMonth);
            });
        }

        if (searchQuery) {
            filteredData = filteredData.filter(item =>
                item.title.toLowerCase().includes(searchQuery.toLowerCase())
            );
        }

        setAvailableMonths([...monthsSet].sort((a, b) => a - b));
        setFilteredData(filteredData);
        console.log("Filtered data:", filteredData); // Debugging log
    }, [selectedYear, selectedMonth, searchQuery, data]);

    const emptyArray = [
        { title: "Circular Title" },
        { title: "Circular Title" },
    ];
  return (
     <>
       <Header />
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li>  Download</li>
                 <li>  Circular</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>  Circular </h1>
                    </div>
                </div>
                <div className="row tabs-dynamic"> 
                <div className="col-md-3">
                    <div className="month-selection">
                        <select id="yearSelection" onChange={(e) => setSelectedYear(e.target.value)} value={selectedYear}>
                            <option value="All">All Years</option>
                            {yearRanges.map((yr, index) => (
                                <option key={index} value={yr}>{yr}</option>
                            ))}
                        </select>
                    </div>
                </div> 
                <div className="col-md-3">
                    <div className="searchBox">
                        <input 
                            type="text" 
                            id="myInput" 
                            name="name" 
                            autoComplete="off" 
                            placeholder="Search Here.." 
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </div>
                </div>
            </div>
            <div className="clr"></div>
                <div className="row"> 
                {filterData?.length > 0 ? filterData?.filter((item) =>  item.category === "Circular").map((item, index) => (
                    
                            <div className="col-lg-4" key={index}> 
                            <Link to={`https://webapi.entab.info/api/image/${item?.attachments[0]}`} target="_blank">
                                <div class="sample-blk">
                                  <div>
                                       <p> {item.title}</p>
                                      <span>{item.date}</span>
                                  </div> 
                                  <LazyLoad><img alt=" St. Joseph’s High School, Dabwali" src="https://webapi.entab.info/api/image/SJHSMD/public/Images/pdf.png" class="img-fluid"/> 
                                  </LazyLoad>
                                  </div> 
                               </Link>
                        </div>
                        ))
                    : (
                        <div className="col-lg-4"> 
                        <a href="#" target="_blank"> 
                            <div class="sample-blk">
                              <div>
                                   <p> Title</p>
                                  <span>DD.MM.YY</span>
                              </div> 
                              <LazyLoad> <img alt=" St. Joseph’s High School, Dabwali" src="https://webapi.entab.info/api/image/SJHSMD/public/Images/pdf.png" class="img-fluid"/>
                              </LazyLoad>
                               </div> 
                        </a>  
                    </div>
                      )}
                </div>
            </div>
         </div>
         <Footer />
     </>
  )
}

export default Circular

