import React, {useEffect } from 'react';
import { Link } from 'react-router-dom'
import Header from'../Component/Header'
import Footer from'../Component/Footer'
import LazyLoad from 'react-lazyload';

const About = () => {
    useEffect(() => {
        document.title = 'About Us - St. Joseph’s High School, Dabwali ';  
    }, []);  
  return (
     <>
     <Header />
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li>  School</li>
                 <li>  About Us</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>  About Us  </h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                    <div className="clearfix">
                    <LazyLoad>    <img src="https://webapi.entab.info/api/image/SJHSMD/public/Images/our_school.jpg" alt="St. Joseph’s High School, Dabwali" className="col-lg-5 col-md-6 float-md-end  left-img img-fluid"/></LazyLoad>
                    <p><b>St. Joseph’s High School, Dabwali</b> is run by the registered Society of Pilar, Punjab- Haryana, a branch of the Society of Pilar, Goa.The school is run by the religious minority in fulfillment of its constitution right under article 30 (1).This school is affiliated to the Council for the Indian School Certificate Examination, New Delhi. </p>
 
                    <p>St. Joseph’s High School was opened in<b> 1970</b> to cater to the pressing educational needs of Mandi Dabwali and the surroundings villages. The school aims at all round development of the child, moral along with the hope of drawing out the best in each, so as to enable its pupils to blossom into gems of persons and capable citizens of our country. </p>
                    
                    <p>The school caters to the students up to Std-X. The medium of instruction is English. Hindi and Punjabi are also taught as additional languages. Having gauged the usefulness of computers, smart classes, the school gives stress to training in Computer Science.An atmosphere of warmth and caring is provided by capable teachers to each child so that he/she blossoms to their full stature. </p>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
     </>
  )
}

export default About

