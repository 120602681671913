import React from 'react';
import { Link } from 'react-router-dom';
import LazyLoad from 'react-lazyload';

const Footer = () => {
  return (
    <>
           <div className="footer padded-sec">
        <div className='abs-list ftrShape1'> 
        </div>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-xl-3 col-lg-6 col-sm-6 col-xs-12'>
               <Link to="/"><div className='ftr-logo'> 
               <LazyLoad> <img src="https://webapi.entab.info/api/image/SJHSMD/public/Images/footerlogo.png" alt="SJHSM" />  </LazyLoad>
              </div>
              </Link>
            </div>
            
            <div className='col-xl-2 col-lg-6 col-sm-6 col-xs-12'>
              <div className='quick-link'>
                <h3>  About Us</h3>
                <ul>
                <li><Link to="/"><i class="bi bi-caret-right-fill"></i>  Home </Link></li>
                <li><Link to="/OurStaff"><i class="bi bi-caret-right-fill"></i>   Our Staff</Link></li> 
                <li><Link to="/Tc"><i class="bi bi-caret-right-fill"></i>   Transfer Certificate</Link></li>
                <li><Link to="/Pphelp"><i class="bi bi-caret-right-fill"></i>    Parent Portal</Link></li>
                <li><Link to="/ErpSoftware"><i class="bi bi-caret-right-fill"></i>   Erp Software</Link></li>
                </ul>
              </div>
            </div>
            <div className='col-xl-2 col-lg-6 col-sm-6 col-xs-12'>
            <div className='quick-link'>
              <h3>Useful Links</h3>
              <ul>
              <li><Link to="/"><i class="bi bi-caret-right-fill"></i>   Online Admission Form </Link></li>
              <li><Link to="/"><i class="bi bi-caret-right-fill"></i>   Admission Form Download </Link></li>
               <li><Link to="/HomeWork"><i class="bi bi-caret-right-fill"></i>  Holiday Homework Download </Link></li>
               <li><Link to="/Syllabus"><i class="bi bi-caret-right-fill"></i>  Syllabus Downloads </Link></li> 
               <li><Link to="/ContactUs"><i class="bi bi-caret-right-fill"></i>  Contcat Us </Link></li>
              </ul>
            </div>
          </div>
            <div className='col-xl-3 col-lg-6 col-sm-6 col-xs-12 address'>
              <h3>Address</h3>
              <p> <LazyLoad> <img src="https://webapi.entab.info/api/image/SJHSMD/public/Images/loc.png" alt="SJHSM" /> </LazyLoad> <span><Link to="https://maps.app.goo.gl/YzQ6DFmGiQ5XG3LU7" target="_blank">Mandi Dabwali, Bathinda Road, Dist- Sirsa, Haryana. Pin Code-125104</Link></span></p>
              
              <p> <LazyLoad> <img src="https://webapi.entab.info/api/image/SJHSMD/public/Images/mail.png" alt="SJHSM" /> </LazyLoad> <span> <a href="mailto: stjosephs1973@gmail.com">stjosephs1973@gmail.com</a> </span></p>
              <p> <LazyLoad> <img src="https://webapi.entab.info/api/image/SJHSMD/public/Images/call.png" alt="SJHSM" /> </LazyLoad> <span><a href="tel: +91-9729026844">+91-9729026844</a> | <a href="tel: +91-9729526844">+91-9729526844</a></span></p>
              <p> <LazyLoad> <img src="https://webapi.entab.info/api/image/SJHSMD/public/Images/web.png" alt="SJHSM" /> </LazyLoad> <span> <a href="www.stjosephpilar.com">www.stjosephpilar.com</a> </span></p>
              </div>
            <div className='col-xl-2 col-lg-6 col-sm-6 col-xs-12 sociallink'> 
             
                   <h6>Follow Us</h6>
                    <ul className='socialMedia'> 
                
                    <li><Link to="/" target="_blank"> <LazyLoad> <img src="https://webapi.entab.info/api/image/SJHSMD/public/Images/youtube.png" alt="SJHSM" /></LazyLoad></Link></li>
                    <li><Link to="/" target="_blank"> <LazyLoad> <img src="https://webapi.entab.info/api/image/SJHSMD/public/Images/twitter.png" alt="SJHSM" /></LazyLoad></Link></li>
                    <li><Link to="/" target="_blank"> <LazyLoad> <img src="https://webapi.entab.info/api/image/SJHSMD/public/Images/facebook.png" alt="SJHSM" /></LazyLoad></Link></li>
                      <li><Link to="/" target="_blank"> <LazyLoad> <img src="https://webapi.entab.info/api/image/SJHSMD/public/Images/linked.png" alt="SJHSM" /></LazyLoad></Link></li>
                  </ul>
                 
            </div>
          </div> 
        </div>
      </div>
      <div className='copyright'>
      ©️ 2024 SJHSM | Designed & developed by Entab Infotech: CampusCare®️ 
  </div>  
    </>
  );
}

export default Footer;
