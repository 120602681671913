import { useEffect, useState } from 'react';
import { getGalleryInner } from '../Service/Api';
import Slider from 'react-slick';
import LazyLoad from 'react-lazyload';

const KioskActivities = () => {
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        const getData = async () => {
            try {
                const response = await getGalleryInner();
                const latestEntries = response.slice(0, 2); // Fetch latest 2 entries
                const formattedData = latestEntries.map(entry => ({
                    title: entry.title,
                    attachments: entry.attachments.slice(0, 20), // Limit to 10 attachments per category
                }));
                setCategories(formattedData);
            } catch (error) {
                console.error('Error fetching data:', error);
                setCategories([]);
            }
        };
        getData();
    }, []);

    // Settings for the outer slider (Category-level)
    const outerSettings = {
        autoplay: true,
        infinite: true,
        autoplaySpeed: 80000, // Time each category is displayed
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        adaptiveHeight: true,
    };

    // Settings for the inner slider (Attachment-level)
    const innerSettings = {
        autoplay: true,
        infinite: true,
        autoplaySpeed: 4000, // Time each attachment is displayed
        speed: 900,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        adaptiveHeight: true,
        fade: true,
    };

    return (
        <div className="kiosk-gallery-blk main">
            {categories.length > 0 ? (
                <Slider {...outerSettings}>
                    {categories.map((category, categoryIndex) => (
                        <div key={categoryIndex} className="kiosk-activity-block">
                            {/* Category Title */}
                           
                            {/* Attachments for the Current Category */}
                            <Slider {...innerSettings}>
                                {category.attachments.map((attachment, attachmentIndex) => (
                                    <div className="gallery-block" key={attachmentIndex}>
                                        <div className="galleryImg">
                                            <LazyLoad>
                                                <img
                                                    src={`https://webapi.entab.info/api/image?url=${attachment}`}
                                                    className="d-block w-100"
                                                    alt={category.title || "Image"}
                                                />
                                            </LazyLoad>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                            <h2 className="text-center">{category.title}</h2>
                        </div>
                    ))}
                </Slider>
            ) : (
                <div className="default-gallery-block">
                    <div className="galleryImg">
                        <LazyLoad>
                            <img
                                src="https://webapi.entab.info/api/image/SJHSMD/public/Images/1.jpg"
                                alt="Default"
                                className="d-block w-100"
                            />
                        </LazyLoad>
                    </div>
                    <h3 className="text-center">No Activities Found</h3>
                </div>
            )}
        </div>
    );
};

export default KioskActivities;
