import React, { useEffect, useState } from "react";
import { getGalleryInner } from "../Service/Api";
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import LazyLoad from 'react-lazyload';
const Gallery = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true); 

  useEffect(() => {
    const fetchData = async () => {
      try {
        const galleryData = await getGalleryInner();
        setData(galleryData);
      } catch (error) {
        console.error("Error fetching gallery data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const emptyData = [
    { title: "St. Joseph High School"}, 
    { title: "St. Joseph High School"},
    { title: "St. Joseph High School"},
    { title: "St. Joseph High School"},
  ];

  const settings = {
    autoplay: true,
    infinite: true,
    autoplaySpeed: 2000,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3, 
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2, 
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2, 
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1, 
        }
      }
    ]
  };

  const filteredData = data.filter(item => item.category === "PhotoGallery");

  return (
    <>
       
        <Slider {...settings}>
        {filteredData.length >= 4 &&
          filteredData.map((item, index) => (
         
            <div className="item" key={index}>
              <div className="galleryimg">
                <div className="galleryimgbox">
                <LazyLoad><img
                    src={`https://webapi.entab.info/api/image/${item.attachments[0]}`}
                    alt={item.title}
                    className="img-fluid"
                  /> </LazyLoad>
                 

                <div className="gallerydesc">
                  <Link to="/" className="gallery_button">View Gallery</Link>
                  <h6>{item.title}</h6>
                </div>
              </div>  
              </div>
            </div>
          ))}
          {filteredData.length < 4 &&
            filteredData.length > 0 &&
            filteredData.map((item, index) =>
              item.attachments.map((img) => (
                <div className="galleryimg">
                     <div className="galleryimgbox">
                      <img
                        src={`https://webapi.entab.info/api/image/${img}`}
                        className="img-fluid"
                        alt="Delhi Police Public School Wazirabad"
                      />
                      <div className="gallerydesc">
                        <Link to="/" className="gallery_button">View Gallery</Link>
                        <h6>{item.title}</h6>
                      </div>
                    </div>
                  
                </div>
              ))
            )}
        </Slider>
      
    </>
  );
};

export default Gallery;
