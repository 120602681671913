import React from 'react'
import { useEffect } from 'react';
import { useLocation, NavLink } from 'react-router-dom';
import Highlights from'../Component/Highlights' 
import LazyLoad from 'react-lazyload';
const Header = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
  return (
        <> 
        <div className="header"> 
        <div className="logo">
            <NavLink to="/"><img src="https://webapi.entab.info/api/image/SJHSMD/public/Images/logo.png" alt=" St. Joseph High School" className="img-fluid"/></NavLink>
        </div>
        <div className="header-nav">
        <Highlights/>
            <nav className="navbar navbar-expand-lg">
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main_nav">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="main_nav">
                <ul className="navbar-nav"> 
                    <li className="nav-item">  <NavLink className="nav-link" to="/">Home </NavLink> </li>
                    <li className="nav-item dropdown"> 
                        <div className="nav-link" data-bs-toggle="dropdown"> School</div> 
                        <ul className="dropdown-menu fade-up"> 
                        <li><NavLink  className="dropdown-item" to="/Patron">  Patron</NavLink></li>
                        <li><NavLink  className="dropdown-item" to="/About">About</NavLink></li>
                        <li><NavLink  className="dropdown-item" to="/AboutSociety">About Society</NavLink></li>   
                        <li><NavLink  className="dropdown-item" to="/SchoolInfrastructure">School Infrastructure</NavLink></li>   
                        <li><NavLink  className="dropdown-item" to="/"> Facility</NavLink></li> 
                        </ul>   
                        </li>
                        <li className="nav-item dropdown"> 
                        <div className="nav-link" data-bs-toggle="dropdown"> Message </div> 
                        <ul className="dropdown-menu fade-up"> 
                        <li><NavLink  className="dropdown-item" to="/PrincipalMessage">Principal's Message</NavLink></li>
                         <li><NavLink  className="dropdown-item" to="/ManagerMessage">Manager's Message</NavLink></li>
                        </ul>   
                        </li>
                        <li className="nav-item dropdown"> 
                        <div className="nav-link" data-bs-toggle="dropdown">  Achievement</div> 
                        <ul className="dropdown-menu fade-up"> 
                            <li><NavLink  className="dropdown-item" to="/Toppers">  Toppers</NavLink></li>
                            <li><NavLink  className="dropdown-item" to="/">Our Achievement</NavLink></li> 
                        </ul>   
                     </li> 
                     <li className="nav-item dropdown"> 
                            <div className="nav-link" data-bs-toggle="dropdown">  Office bearers</div> 
                            <ul className="dropdown-menu fade-up"> 
                                <li><NavLink  className="dropdown-item" to="/SchoolCabinet"> School Cabinet</NavLink></li>
                                <li><NavLink  className="dropdown-item" to="/OurStaff">Our Staff</NavLink></li> 
                            </ul>   
                      </li>
                      <li className="nav-item dropdown"> 
                      <div className="nav-link" data-bs-toggle="dropdown"> Gallery</div> 
                      <ul className="dropdown-menu fade-up"> 
                          <li><NavLink  className="dropdown-item" to="/Gallery"> Photo Gallery</NavLink></li>
                          <li><NavLink  className="dropdown-item" to="/VideoGallery">Video Gallery</NavLink></li> 
                      </ul>   
                   </li>
                   <li className="nav-item dropdown"> 
                   <div className="nav-link" data-bs-toggle="dropdown"> Download </div> 
                   <ul className="dropdown-menu fade-up"> 
                       <li><NavLink  className="dropdown-item" to="/Syllabus"> Syllabus</NavLink></li>
                       <li><NavLink  className="dropdown-item" to="/HomeWork">Homework</NavLink></li> 
                        <li><NavLink  className="dropdown-item" to="/Circular">Circular</NavLink></li> 
                   </ul>   
                </li>
                    <li className="nav-item">  <NavLink className="nav-link" to="/Contactus">Contact  </NavLink> </li>
                    <li className="nav-item">  <NavLink className="nav-link" to="https://entab.online/SJSMHR" target='_blank'>Admission  </NavLink> </li>
                    {/* <li className="nav-item">  <NavLink className="nav-link" to="/Login">Login  </NavLink> </li> */}
                       
                </ul>
                </div> 
           </nav>
        </div> 
    </div>
    </>
  )
}

export default Header
