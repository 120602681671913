import React, {useEffect } from 'react';
import Header from'../Component/Header'
import Footer from'../Component/Footer' 
import { Link } from 'react-router-dom'
const Patron = () => {
    useEffect(() => {
        document.title = 'Patron - St. Joseph’s High School, Dabwali ';  
    }, []);  
  return (
     <>
       <Header />
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li>  School</li>
                 <li>  Patron</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>  Patron  </h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <p>St. Francis Xavier who was born in Spain in 1506 to a noble family, was influenced by Ignatious Loyola. Ignatious Loyola who started the Society of Jesus, recruited Francis Xavier into this order.</p> 
                        <p>St. Francis Xavier who landed in Goa during 1542 specifically to stem the root amongst the faithful, in a short period of time caused a major awakening. He was instrumental in building many churches and institutions in Goa and elsewhere. He travelled across Malacca, China and Japan.</p>  
                        <p>St. Francis Xavier, the patron saint of Goa, affectionately called as Goencho saiba or Lord of Goa died in 1552 on his way to China. The body brought in 1554 to Goa, is kept in the Basilica of Bom Jesus. The sacred relic of St. Francis Xavier normally housed in a silver casket in the Bom Jesus church, is brought in a ceremonious procession by several priests to the SE cathedral every 10 years for an exposition.</p> 
                        <p>He was beatified by Pope Paul V in 1619 and canonized together with St. Ignatius by Pope Gregory XV, on March 12th, 1622</p>
                    </div>
                </div>
            </div>
         </div>
         <Footer />
     </>
  )
}

export default Patron

