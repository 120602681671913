import React, {useEffect } from 'react';
import Header from'../Component/Header'
import Footer from'../Component/Footer'
import { Link } from 'react-router-dom'
import LazyLoad from 'react-lazyload';
const Contactus = () => {
    useEffect(() => {
        document.title = 'Contact Us - St. Joseph’s High School, Dabwali ';  
    }, []);  
  return (
     <>
       <Header />
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li>  
                 <li>  Contact Us</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>  Contact Us  </h1>
                    </div>
                </div> 
                <div class="enquiryform">
                <div class="enquiryformimg">
                    <div class="locationbox">
                        <div class="locationdiv">
                            <div class="locationicon"><i class="bi bi-geo-alt"></i></div>
                            <div class="locationdetails"><h5>address</h5><p><a href="https://maps.app.goo.gl/YzQ6DFmGiQ5XG3LU7" target="_blank">  Mandi Dabwali, Bathinda Road, Dist- Sirsa, Haryana. Pin Code-125104.</a></p></div>
                        </div>
                        <div class="locationdiv">
                            <div class="locationicon"><i class="bi bi-telephone"></i></div>
                            <div class="locationdetails"><h5>Phone</h5><p><a href="tel:+91-9729026844"> +91-9729026844,  +91-9729526844 </a> </p></div>
                        </div>
                        <div class="locationdiv">
                            <div class="locationicon"><i class="bi bi-envelope"></i></div>
                            <div class="locationdetails"><h5>Email</h5><p><a href="mailto:stjosephs1973@gmail.com"> stjosephs1973@gmail.com</a></p></div>
                        </div>
                    </div>
                </div>
                <div class="contactdiv">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3456.445909650596!2d74.71065531224426!3d29.966612574858306!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3916d92e16fd4471%3A0xcf284cf760f71a41!2sSt%20Joseph&#39;s%20High%20School!5e0!3m2!1sen!2sin!4v1726640465920!5m2!1sen!2sin" width="100%" height="470" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" style={{border: "0px"}}></iframe>
                </div>
            </div>
            </div>
         </div>
         <Footer />
     </>
  )
}

export default Contactus

