import React from 'react'
import { Link } from 'react-router-dom'
import LazyLoad from 'react-lazyload';
const Campuscare = () => {
  return (
     <>
      <div className="campuslogo">
         <Link to="https://entab.online/SJSMHR" target="_blank"> 
            <LazyLoad> 
               <img src="https://webapi.entab.info/api/image/SJHSMD/public/Images/Campulogo.png" className="img-fluid float-end campus" alt="SJHSMD"/>
            </LazyLoad>
         </Link>
      </div>
      </>
        
  )
}

export default Campuscare
