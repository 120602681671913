import React, {useEffect } from 'react';
import Header from'../Component/Header'
import Footer from'../Component/Footer'
import { Link } from 'react-router-dom'
import LazyLoad from 'react-lazyload';
const SchoolCabinet = () => {
    useEffect(() => {
        document.title = 'School Cabinet - St. Joseph’s High School, Dabwali ';  
    }, []);  
  return (
     <>
       <Header />
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li>  Office Bearers</li>
                 <li>  School Cabinet</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>  School Cabinet  </h1>
                    </div>
                </div>
                <div className="row">
                <div className="col-lg-4 col-md-6 col-sm-6">
                <a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SJHSMD/public/Images/schoolcabinet.jpg">
                <LazyLoad><img className="img-fluid smartclass" src="https://webapi.entab.info/api/image/SJHSMD/public/Images/schoolcabinet.jpg" alt="St. Joseph’s High School, Dabwali"/> </LazyLoad>
                </a>
                </div>
    <div className="col-lg-4 col-md-6 col-sm-6">
    <a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SJHSMD/public/Images/schoolcabinet1.jpg">
    <LazyLoad><img className="img-fluid smartclass" src="https://webapi.entab.info/api/image/SJHSMD/public/Images/schoolcabinet1.jpg" alt="St. Joseph’s High School, Dabwali"/> </LazyLoad>
    </a>
    </div>
    <div className="col-lg-4 col-md-6 col-sm-6">
    <a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SJHSMD/public/Images/schoolcabinet2.jpg">
    <LazyLoad><img className="img-fluid smartclass" src="https://webapi.entab.info/api/image/SJHSMD/public/Images/schoolcabinet2.jpg" alt="St. Joseph’s High School, Dabwali"/> </LazyLoad>
    </a>
    </div>
    <div className="col-lg-3 col-md-6 col-sm-6">
    <a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SJHSMD/public/Images/schoolcabinet3.jpg">
    <LazyLoad><img className="img-fluid smartclass" src="https://webapi.entab.info/api/image/SJHSMD/public/Images/schoolcabinet3.jpg" alt="St. Joseph’s High School, Dabwali"/> </LazyLoad>
    </a>
    </div>
    <div className="col-lg-3 col-md-6 col-sm-6">
    <a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SJHSMD/public/Images/schoolcabinet4.jpg">
    <LazyLoad><img className="img-fluid smartclass" src="https://webapi.entab.info/api/image/SJHSMD/public/Images/schoolcabinet4.jpg" alt="St. Joseph’s High School, Dabwali"/> </LazyLoad>
    </a>
    </div>
    <div className="col-lg-3 col-md-6 col-sm-6">
    <a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SJHSMD/public/Images/schoolcabinet5.jpg">
    <LazyLoad><img className="img-fluid smartclass" src="https://webapi.entab.info/api/image/SJHSMD/public/Images/schoolcabinet5.jpg" alt="St. Joseph’s High School, Dabwali"/> </LazyLoad>
    </a>
    </div>
    <div className="col-lg-3 col-md-6 col-sm-6">
    <a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SJHSMD/public/Images/schoolcabinet6.jpg">
    <LazyLoad><img className="img-fluid smartclass" src="https://webapi.entab.info/api/image/SJHSMD/public/Images/schoolcabinet6.jpg" alt="St. Joseph’s High School, Dabwali"/> </LazyLoad>
    </a>
    </div>
    <div className="col-lg-3 col-md-6 col-sm-6">
    <a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SJHSMD/public/Images/schoolcabinet7.jpg">
    <LazyLoad><img className="img-fluid smartclass" src="https://webapi.entab.info/api/image/SJHSMD/public/Images/schoolcabinet7.jpg" alt="St. Joseph’s High School, Dabwali"/> </LazyLoad>
    </a>
    </div>
    <div className="col-lg-3 col-md-6 col-sm-6">
    <a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/SJHSMD/public/Images/schoolcabinet8.jpg">
    <LazyLoad><img className="img-fluid smartclass" src="https://webapi.entab.info/api/image/SJHSMD/public/Images/schoolcabinet8.jpg" alt="St. Joseph’s High School, Dabwali"/> </LazyLoad>
    </a>
    </div>
 </div> 
            </div>
         </div>
         <Footer />
     </>
  )
}

export default SchoolCabinet

