import React, {useEffect } from 'react';
import Header from'../Component/Header'
import Footer from'../Component/Footer'
import { Link } from 'react-router-dom' 
const OurStaff = () => {
    useEffect(() => {
        document.title = 'Our Staff - St. Joseph’s High School, Dabwali ';  
    }, []);  
  return (
     <>
       <Header />
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li>  Office Bearers</li>
                 <li>  Our Staff</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>  Our Staff  </h1>
                    </div>
                </div>
                <div className="row">
                         
 <div className="table-responsive maintable">
 <table className="table">
    <thead>
           <tr>
               <th>SI.No</th>
            <th>Name</th>
            <th>Designation </th>
          </tr>
    </thead>
    <tbody><tr><td>1</td>
               <td>MRS. DALVIR KAUR</td>
           <td>TEACHER</td>
       </tr><tr><td>2</td>
               <td>MR. SANDEEP GARG</td>
           <td>TEACHER</td>
       </tr><tr><td>3</td>
               <td>MR.GURPREET SINGH SIDHU</td>
           <td>TEACHER ( COORDINATOR)</td>
       </tr><tr><td>4</td>
               <td>MRS. SHIMAL BISHNOI</td>
           <td>TEACHER</td>
       </tr><tr><td>5</td>
               <td>MRS. ROBINDER SIDHU</td>
           <td>TEACHER</td>
       </tr><tr><td>6</td>
               <td>MRS. VEERPAL KAUR (SR)</td>
           <td>TEACHER</td>
       </tr><tr><td>7</td>
               <td>MRS. MAMTA ARORA</td>
           <td>TEACHER ( COORDINATOR)</td>
       </tr><tr><td>8</td>
               <td>MRS. ANJANA KADIAN</td>
           <td>TEACHER</td>
       </tr><tr><td>9</td>
               <td>MRS. JASDEEP KAUR</td>
           <td>TEACHER</td>
       </tr><tr><td>10</td>
               <td>MRS. ARCHNA RANI</td>
           <td>TEACHER</td>
       </tr><tr><td>11</td>
               <td>MRS. KANCHAN JINDAL</td>
           <td>TEACHER</td>
       </tr><tr><td>12</td>
               <td>MISS. ABHITA</td>
           <td>TEACHER</td>
       </tr><tr><td>13</td>
               <td>MRS. RENU MIDDHA</td>
           <td>TEACHER</td>
       </tr><tr><td>14</td>
               <td>MRS. JYOTI KHOKHAR</td>
           <td>TEACHER</td>
       </tr><tr><td>15</td>
               <td>MRS. SHINU GARG</td>
           <td>TEACHER</td>
       </tr><tr><td>16</td>
               <td>MRS. MANDEEP KAUR</td>
           <td>TEACHER</td>
       </tr><tr><td>17</td>
               <td>MRS. VEERPAL BRAR</td>
           <td>TEACHER</td>
       </tr><tr><td>18</td>
               <td>MRS ANNIE DASS</td>
           <td>TEACHER</td>
       </tr><tr><td>19</td>
               <td>MRS. SONIA DHALIWAL</td>
           <td>TEACHER</td>
       </tr><tr><td>20</td>
               <td>MRS. HARVINDER KAUR SIDHU</td>
           <td>TEACHER</td>
       </tr><tr><td>21</td>
               <td>MRS. ASHA RANI</td>
           <td>TEACHER</td>
       </tr><tr><td>22</td>
               <td>MRS. MOHINI KAMRA</td>
           <td>TEACHER</td>
       </tr><tr><td>23</td>
               <td>MRS. MONIKA PRUTHI</td>
           <td>TEACHER</td>
       </tr><tr><td>24</td>
               <td>MR.PARVINDER SINGH</td>
           <td>TEACHER</td>
       </tr><tr><td>25</td>
               <td>MRS. NEETU WILSON</td>
           <td>TEACHER</td>
       </tr><tr><td>26</td>
               <td>MRS. AMANDEEP KAUR</td>
           <td>TEACHER</td>
       </tr><tr><td>27</td>
               <td>MISS GAGANDEEP KAUR</td>
           <td>TEACHER</td>
       </tr><tr><td>28</td>
               <td>MRS. HARPREET KAUR</td>
           <td>TEACHER</td>
       </tr><tr><td>29</td>
               <td>MS. JAGDEEP</td>
           <td>TEACHER</td>
       </tr><tr><td>30</td>
               <td>MRS.RAJKIRAN KAUR</td>
           <td>TEACHER</td>
       </tr><tr><td>31</td>
               <td>MRS. KIRAN DUREJA</td>
           <td>TEACHER</td>
       </tr><tr><td>32</td>
               <td>MR. ISHU MITTAL</td>
           <td>TEACHER</td>
       </tr><tr><td>33</td>
               <td>MRS. MANPREET KAUR</td>
           <td>TEACHER</td>
       </tr><tr><td>34</td>
               <td>MISS NAAZ</td>
           <td>TEACHER</td>
       </tr><tr><td>35</td>
               <td>MR.PARDEEP SINGH</td>
           <td>TEACHER</td>
       </tr><tr><td>36</td>
               <td>MRS. KAVITA BANSAL</td>
           <td>TEACHER</td>
       </tr><tr><td>37</td>
               <td>MRS. MONIKA MEHTA</td>
           <td>TEACHER</td>
       </tr><tr><td>38</td>
               <td>MR. SAJAN</td>
           <td>TEACHER</td>
       </tr><tr><td>39</td>
               <td>MRS. USHA</td>
           <td>TEACHER</td>
       </tr><tr><td>40</td>
               <td>MISS JANNY</td>
           <td>TEACHER</td>
       </tr><tr><td>41</td>
               <td>MRS. SHINAM</td>
           <td>TEACHER</td>
       </tr><tr><td>42</td>
               <td>MISS.DAISY</td>
           <td>TEACHER</td>
       </tr><tr><td>43</td>
               <td>MRS.  JYOTI BHATEJA</td>
           <td>TEACHER</td>
       </tr><tr><td>44</td>
               <td>KULBIR KAUR</td>
           <td>TEACHER</td>
       </tr><tr><td>45</td>
               <td>MISS. ASHIMA</td>
           <td>TEACHER</td>
       </tr><tr><td>46</td>
               <td>MR. LOVEDEEP SINGH</td>
           <td>TEACHER</td>
       </tr><tr><td>47</td>
               <td>MRS. GURTEJ KAUR</td>
           <td>TEACHER</td>
       </tr><tr><td>48</td>
               <td>MISS. APURVA</td>
           <td>TEACHER</td>
       </tr><tr><td>49</td>
               <td>MR. VISHAL</td>
           <td>TEACHER</td>
       </tr><tr><td>50</td>
               <td>MR. SUKHTAR</td>
           <td>TEACHER</td>
       </tr><tr><td>51</td>
               <td>MISS. AARTI</td>
           <td>TEACHER</td>
       </tr><tr><td>52</td>
               <td>MRS.REENA NAGPAL</td>
           <td>TEACHER</td>
       </tr><tr><td>53</td>
               <td>MRS. SONA RANI</td>
           <td>TEACHER</td>
       </tr><tr><td>54</td>
               <td>RENU BISHNOI</td>
           <td>TEACHER</td>
       </tr><tr><td>55</td>
               <td>HARPREET KAUR SANDHU (SR BLK)</td>
           <td>TEACHER</td>
       </tr><tr><td>56</td>
               <td>MRS RAJVEER KAUR</td>
           <td>TEACHER</td>
       </tr><tr><td>57</td>
               <td>MR. SUKHWINDER SINGH</td>
           <td>IT - COORDINATOR</td>
       </tr><tr><td>58</td>
               <td>MISS LUCITA ROWEENA GOMES</td>
           <td>CLERK</td>
       </tr><tr><td>59</td>
               <td>MRS. ANJANA ANTHONY</td>
           <td>TEACHER</td>
       </tr><tr><td>60</td>
               <td>MRS. RAJNI SHARMA</td>
           <td>TEACHER</td>
       </tr><tr><td>61</td>
               <td>MRS. ROJI</td>
           <td>TEACHER</td>
       </tr><tr><td>62</td>
               <td>MRS. AMANDEEP KAUR (L.K.G)</td>
           <td>TEACHER</td>
       </tr><tr><td>63</td>
               <td>MRS. MEENU NAGPAL</td>
           <td>TEACHER</td>
       </tr><tr><td>64</td>
               <td>MRS. ANUSHASITA SETHI</td>
           <td>TEACHER</td>
       </tr><tr><td>65</td>
               <td>MRS. KIRTI</td>
           <td>TEACHER</td>
       </tr><tr><td>66</td>
               <td>INDU LAZAR</td>
           <td>TEACHER</td>
       </tr><tr><td>67</td>
               <td>MR. SATISH KUMAR</td>
           <td>TEACHER</td>
       </tr><tr><td>68</td>
               <td>ASHISH DHALIWAL</td>
           <td></td>
       </tr><tr><td>69</td>
               <td>MONIKA RANI</td>
           <td>TEACHER</td>
       </tr><tr><td>70</td>
               <td>AMRITPAL KAUR</td>
           <td>TEACHER</td>
       </tr><tr><td>71</td>
               <td>NAINY</td>
           <td>TEACHER</td>
       </tr><tr><td>72</td>
               <td>AMRITPAL KAUR</td>
           <td>TEACHER</td>
       </tr><tr><td>73</td>
               <td>REENA DHALIWAL</td>
           <td>RECEPTIONIST</td>
       </tr><tr><td>74</td>
               <td>GAGANDEEP KAUR</td>
           <td>TEACHER</td>
       </tr><tr><td>75</td>
               <td>ANUREET SWATI</td>
           <td>TEACHER</td>
       </tr><tr><td>76</td>
               <td>SWEZEL FERNANDES</td>
           <td>TEACHER</td>
       </tr><tr><td>77</td>
               <td>SENIKA SILVA</td>
           <td>TEACHER</td>
       </tr><tr><td>78</td>
               <td>ANUREET KAUR</td>
           <td>TEACHER</td>
       </tr><tr><td>79</td>
               <td>JAGDEEP KAUR</td>
           <td>TEACHER</td>
       </tr><tr><td>80</td>
               <td>ANMOLPREET KAUR</td>
           <td>TEACHER</td>
       </tr><tr><td>81</td>
               <td>KULVIR</td>
           <td>TEACHER</td>
       </tr><tr><td>82</td>
               <td>SIENNA COLACO</td>
           <td>TEACHER</td>
       </tr><tr><td>83</td>
               <td>NARINDER KAUR</td>
           <td>TEACHER  
</td>
 </tr></tbody>
 </table>
</div>
               </div> 
            </div>
         </div>
         <Footer />
     </>
  )
}

export default OurStaff

