import { useEffect, useState } from 'react'; 
import { getGalleryInner } from '../Service/Api';
import { Carousel } from 'react-bootstrap';
import LazyLoad from 'react-lazyload';
const Achievers = () => {
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [chunkedData, setChunkedData] = useState([]);

    useEffect(() => {
        const getData = async () => {
            try {
                const response = await getGalleryInner();
                setData(response);
                console.log("received res", response);
            } catch (error) {
                console.error('Error fetching data:', error);
                setData([]);
            }
        };
        getData();
    }, []);

    useEffect(() => {
        if (Array.isArray(data)) {
            const year = data.filter((item) => {
                const getYear = new Date(item.date).getFullYear();
                return getYear === 2024 && item.category === "Achivements";
            });
            setFilteredData(year);
        } else {
            setFilteredData([]);
        }
    }, [data]);

    useEffect(() => {
        const chunkArray = (array, size) => {
            const result = [];
            for (let i = 0; i < array.length; i += size) {
                result.push(array.slice(i, i + size));
            }
            return result;
        };

        if (filteredData.length > 0) {
            const chunked = chunkArray(filteredData, 4); // Change 2 to desired chunk size
            setChunkedData(chunked);
        } else {
            setChunkedData([]);
        }
    }, [filteredData]);

    return (
        <>
            <Carousel className="carousel slide kiosk-gallery-blk">
                {chunkedData.length > 0 ? (
                    chunkedData.map((group, index) => (
                        <Carousel.Item key={index}>
                         <div className="d-flex justify-content-center">
                                {group.map((item, idx) => (
                                    <div className="gallery-block" key={idx}>
                                        <div className="galleryImg">
                                        <LazyLoad>   <img src={`https://webapi.entab.info/api/image?url=${item?.attachments[0]}`} alt={item.title || "Image"} /></LazyLoad>
                                        </div>
                                        <div className="galleryContent">
                                            <h3>{item.title || "Default Title"}</h3>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </Carousel.Item>
                    ))
                ) : (
                    <Carousel.Item>
                         <div className="d-flex justify-content-center">
                            <div className="gallery-block">
                                <div className="galleryImg">
                                <LazyLoad>    <img src="https://webapi.entab.info/api/image/SJHSMD/public/Images/1.jpg" alt="Alternate Text" /></LazyLoad>
                                </div>
                                <div className="galleryContent">
                                    <h3>Achievement Title</h3>
                                </div>
                            </div>  
                        </div>
                    </Carousel.Item>
                )}
            </Carousel>
        </>
    );
};

export default Achievers;
